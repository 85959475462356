.projectCardBig {
    margin: 1rem;
    max-width: 90%;
}

.ProjectSectionTitle {
    font-size: 3rem;
    color: #F0ECE5;
}

@media screen and (max-width: 800px) {
    .ProjectSubSection {
        flex-direction: column;
    }
    .SectionTitle {
        font-size: 2.5rem;
    }
    .ProjectSectionTitle {
        font-size: 2rem;
    }
}
@media screen and (min-width: 800px) {
    .ProjectSubSection {
        flex-direction: row;
    }
}

.ProjectCardCell {
    margin: 0.5rem;
}

.ProjectSection {
    margin-top: 1rem;
    box-shadow: none !important;
    max-width: 100vw;
    border-color:#31304D;
}

.SectionTitle {
    font-size: 3rem;
    font-weight: bold;
    margin: 1rem;
    color: #F0ECE5;
}

.SkillCard {
    margin: 0.5rem;
    background: rgba(154,208,194,0);
    margin-bottom: 3rem;
    box-shadow: none !important;
}

.SkillSectionInside {
    align-content: center;
}

.Description {
    font-size: 1.5rem;
    color: #B6BBC4;
}

@media screen and (max-width: 1350px) {
    .AboutSection{
        flex-direction: column;
    }
    .Description {
        padding-left: 10%;
        width: 100%;
        text-align: justify;
    }
    .Name {
        padding-left: 10%;
        margin-top: 2.5rem;
    }
    .ProfilePhoto {
        height: auto;
        max-width: 75%;
        align-self: flex-end;
    }
    .AboutText {
        width: 90%;
        align-items: center;
    }
    .ProfilePhotoContainer {
        width: 100%;
    }
    .navbar{
        display: none;
    }
    .ContactSection{
        display: flex;
    }
    .SmallPageButton {
        display: flex;
        margin: 1rem;
        position: fixed;
        z-index: 100;
        color: #161A30;
    }
    .EducationDiv {
        flex-direction: column;
        align-items: center;
    }
    .Timeline {
        margin-left: 0;
    }
    .TimelineContainer{
        width: 90vw;
    }
}

@media screen and (min-width: 1350px) {
    .ProfilePhoto {
        width: 100%;
        height: auto;
        align-self: flex-end;
        justify-self: flex-end;
    }
    .ProfilePhoto::after{
        box-shadow: 0 0 32px 8px black inset;
        z-index: 10;
    }
    .AboutSection{
        flex-direction: row;
        height: 100vh;
    }
    .Description{
        padding-left: 10%;
        width: 80%;
        text-align: justify;
    }
    .Name {
        padding-left: 10%;
    }
    .AboutText {
        width: 100%;
        margin-bottom: 5rem;
    }
    .ProfilePhotoContainer {
        display: flex;
        width: 80%;
    }
    .navbar {
        background: #161A30;
        color: #F0ECE5;
        display: flex;
        align-items: flex-start;
        position:fixed;
        width: 14vw;
        height: 100%;
    }
    .ContactSection{
        display: none;
    }
    .SmallPageButton {
        display: none;
    }
    .EducationDiv {
        flex-direction: row;
    }
    .Timeline {
        margin-left: 2rem;
        margin-top: 1rem;
    }
    .TimelineContainer{
        width: 50%;
    }
}

.SkillList {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 900px) {
    .SkillSectionInside {
        flex-direction: column;
    }

    .SkillCard {
        margin: 1rem;
        width: 90%;
    }
    .DiagramsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .DiagramContainerSmall {
        width: 90%;
        height: auto;
    }
    .projectCardBig {
        margin: 0rem;
    }
}

@media screen and (min-width: 900px) {
    .SkillSectionInside {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .DiagramsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 60%;
        justify-content: space-between;
    }
    .DiagramContainerSmall {
        width: 49%;
        height: auto;
    }
}

@media screen and (min-width: 1400px) {
    .ProjectCardGrid{
        display: flex;
        flex-direction: row;
    }
    .ProjectCardCell {
        width: 50%;
    }
    .projectCardSmall {
        margin: 1rem;
        max-width: 45%;
    }
}
@media screen and (max-width: 1400px) {
    .ProjectCardGrid{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ProjectCardCell {
        width: 90%;
    }
    .projectCardSmall {
        margin: 1rem;
        max-width: 90%;
    }
}

.link:hover {
    text-decoration: underline;
    color: #0a5f86;
}

.link {
    color: #0e76a8;
}

.FormationDescription {
    font-size: 1rem;
}

.gameLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
}

.projectDescription {
    color: white;
    text-align: justify;
}

.Timeline {
    color: #B6BBC4;
}

.Keywords {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
}

.ProjectCard {
    box-shadow: none !important;
}

.EducationSection {
    max-width: 100vw;
}

.PlaneDiagram {
    width: 100%;
    height: auto;
}