.App {
  text-align: center;
  min-height: 100vh;
}

body{
  max-width: 100vw;

}

.Main {
  display: flex;
  flex-direction: row;
}

* {
  font-family: 'Titillium Web', 'Arial', sans-serif;
}

.MainBody {
  background: #31304D;
  margin-left: 14vw;
}

@media screen and (max-width: 1350px) {
  .MainBody {
    margin: 0;
  }
}